import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import Antd from 'ant-design-vue';
import { Button, message, Layout, Menu, Tag, Tabs, Form, Input, Dropdown, Card, Table, Progress, Checkbox, DatePicker, Tooltip, Select, Descriptions, Pagination, Switch, Modal, Upload, Collapse, Cascader, Row, Col, InputNumber, Radio, Popover, TimePicker, Tree, Slider, TreeSelect, drawer, Popconfirm, Typography, Empty, ConfigProvider, Spin } from 'ant-design-vue';

// import {} from "ant-design-vue"
import 'ant-design-vue/dist/antd.css';
import * as echarts from 'echarts'

import moment from "moment";
// import "moment/locale/zh-cn";

moment.locale("zh-cn");

// window.addEventListener('popstate', function() {
//     history.pushState(null, null, document.URL)
// })
const app = createApp(App);
app.echarts = echarts
app.config.productionTip = false;
app.use(Button).use(Layout).use(Menu).use(Tag).use(Tabs).use(Form).use(Input).use(Dropdown).use(Card).use(Table).use(Progress).use(Checkbox).use(DatePicker)
app.use(Tooltip).use(Select).use(Descriptions).use(Pagination).use(Switch).use(Modal).use(Upload).use(Collapse).use(Cascader).use(Row).use(Col)
app.use(InputNumber).use(Radio).use(Popover).use(TimePicker).use(Tree).use(Slider).use(TreeSelect).use(drawer).use(Popconfirm).use(Typography).use(Empty).use(ConfigProvider)
    .use(Spin)
app.config.globalProperties.$message = message;
app.use(store).use(router).mount('#app')