// import { createRouter, createWebHashHistory } from "vue-router";
import { createRouter, createWebHistory } from 'vue-router'
const routes = [
  {
    path: "/",
    redirect: "/zhuye",
  },
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
    children: [
      {
        path: "/zhuye",
        name: "Zhuye",
        component: () => import("../views/Zhuye.vue"),
      },
      {
        path: "/task_index",
        name: "SmartTask",
        component: () => import("../views/OutBound/SmartTask"),
      },
      // {
      //   path: "/monitor_index",
      //   name: "Monitor",
      //   component: () => import("../views/OutBound/Monitor"),
      // },
      // {
      //   path: "/number_record_all_index",
      //   name: "CallLog",
      //   component: () => import("../views/OutBound/CallLog"),
      // },
      {
        path: "/get_index",
        name: "OpenSea",
        component: () => import("../views/CrmManage/OpenSea.vue"),
      },
      {
        path: "/type_index",
        name: "CustomType",
        component: () => import("../views/CrmManage/CustomType.vue"),
      },
      {
        path: "/blacklist_index",
        name: "BlackList",
        component: () => import("../views/CrmManage/BlackList.vue"),
      },
      {
        path: "/private_customer_index",
        name: "Private",
        component: () => import("../views/CrmManage/Private.vue"),
      },
      {
        path: "/script_index",
        name: "Model",
        component: () => import("../views/Speechcraft/Model.vue"),
      },
      {
        path: "/technology_add",
        name: "SpeechcraftMark",
        component: () => import("../views/Speechcraft/SpeechcraftMark.vue"),
      },
      {
        path: "/classification_list",
        name: "LiveScene",
        component: () => import("../views/Speechcraft/LiveScene/LiveScene.vue"),
      },
      {
        path: "/group_list",
        name: "GroupList",
        component: () => import("../views/Speechcraft/GroupList/GroupList.vue"),
      },
      {
        path: "/technology_list",
        name: "TechnologyList",
        component: () => import("../views/Speechcraft/GroupList/TechnologyList.vue"),
      },
      {
        path: "/check_script_index",
        name: "CheckIndex",
        component: () => import("../views/Speechcraft/CheckIndex/CheckIndex.vue"),
      },
      {
        path: "/brain",
        name: "BrainSetup",
        component: () => import("../views/Speechcraft/BrainSetup/BrainSetup.vue"),
      },
      {
        path: "/rule_update",
        name: "Rules",
        component: () => import("../views/CrmManage/Rules.vue"),
      },
      {
        path: "/custom_index",
        name: "Field",
        component: () => import("../views/CrmManage/Field.vue"),
      },
      {
        path: "/clerk_index",
        name: "SeatManage",
        component: () => import("../views/SeatManage/SeatManage.vue"),
      },
      {
        path: "/list",
        name: "AcdList",
        component: () => import("../views/SeatManage/AcdList.vue"),
      },
      {
        path: "/bumen",
        name: "Bumen",
        component: () => import("../views/SeatManage/Bumen.vue"),
      },
      // {
      //   path: "/ext_update",
      //   name: "ExtensionEdit",
      //   component: () => import("../views/SeatManage/extensionList/ExtensionEdit.vue"),
      // },
      {
        path: "/seat_monitor_index",
        name: "SeatMonitoring",
        component: () => import("../views/SeatManage/SeatMonitoring.vue"),
      },
      {
        path: "/group_task_index",
        name: "AutoCall",
        component: () => import("../views/PeopleCall/AutoCall.vue"),
      },
      // {
      //   path: "/month_task_index",
      //   name: "EveryMonth",
      //   component: () => import("../views/Statistics/EveryMonth.vue"),
      // },
      // {
      //   path: "/auth_setup",
      //   name: "RoleAuth",
      //   component: () => import("../views/Menu/RoleAuth.vue"),
      // },
      {
        path: "/basic_update",
        name: "BaseData",
        component: () => import("../views/SystemSetup/BaseData.vue"),
      },
      {
        path: "/action_log",
        name: "OperationLog",
        component: () => import("../views/SystemSetup/OperationLog.vue"),
      },
      {
        path: "/login_log",
        name: "LoginLog",
        component: () => import("../views/SystemSetup/LoginLog.vue"),
      },
      {
        path: "/setsystem",
        name: "DisposeSetup",
        component: () => import("../views/SystemSetup/DisposeSetup.vue"),
      },
      // {
      //   path: "/menuindex",
      //   name: "MenuModule",
      //   component: () => import("../views/Menu/MenuModule.vue"),
      // },
      {
        path: "/del_index",
        name: "CleanAccount",
        component: () => import("../views/SystemSetup/CleanAccount.vue"),
      },
      {
        path: "/disk_info",
        name: "SystemMonitor",
        component: () => import("../views/SystemSetup/SystemMonitor.vue"),
      },
      // {
      //   path: "/dept_index",
      //   name: "Department",
      //   component: () => import("../views/Organization/Department.vue"),
      // },
      // {
      //   path: "/oem_index",
      //   name: "BigCustom",
      //   component: () => import("../views/Organization/BigCustom.vue"),
      // },
      // {
      //   path: "/mg_index",
      //   name: "SuperIntendent",
      //   component: () => import("../views/Organization/SuperIntendent.vue"),
      // },
      // {
      //   path: "/agent_index",
      //   name: "Agent",
      //   component: () => import("../views/Organization/Agent.vue"),
      // },
      // {
      //   path: "/user_index",
      //   name: "DeviceManagement",
      //   component: () => import("../views/Organization/DeviceManagement.vue"),
      // },
      {
        path: "/wxpush_index",
        name: "WxpushIndex",
        component: () => import("../views/WeixinNote/WxpushIndex.vue"),
      },
      {
        path: "/kdpush_index",
        name: "KdpushIndex",
        component: () => import("../views/WeixinNote/KdpushIndex.vue"),
      },
      // {
      //   path: "/wangguan",
      //   name: "SipSetup",
      //   component: () => import("../views/PbxSetup/SipSetup.vue"),
      // },
      {
        path: "/extmanage",
        name: "Extmanage",
        component: () => import("../views/PbxSetup/Extmanage.vue"),
      },
      {
        path: "/outbound",
        name: "Outbound",
        component: () => import("../views/ReportForm/Outbound.vue"),
      },
      {
        path: "/artificial",
        name: "Artificial",
        component: () => import("../views/ReportForm/Artificial.vue"),
      },
      {
        path: "/team",
        name: "Team",
        component: () => import("../views/ReportForm/Team.vue"),
      },
      {
        path: "/reception",
        name: "Reception",
        component: () => import("../views/ReportForm/Reception.vue"),
      },
      {
        path: "/intention",
        name: "Intention",
        component: () => import("../views/ReportForm/Intention.vue"),
      },
      {
        path: "/a_index",
        name: "BigData",
        component: () => import("../views/BigData/BigData.vue"),
      },
      {
        path: "/instructions",
        name: "Instructions",
        component: () => import("../views/BigData/Instructions.vue"),
      },
      {
        path: "/data_record",
        name: "ExportRecord",
        component: () => import("../views/BigData/ExportRecord.vue"),
      },
      // {
      //   path: "/asr_list",
      //   name: "AsrInvest",
      //   component: () => import("../views/Finance/AsrInvest.vue"),
      // },
      {
        path: "/onlinerecharge",
        name: "OnlineRecharge",
        component: () => import("../views/Finance/OnlineRecharge.vue"),
      },
      // {
      //   path: "/charge_list",
      //   name: "Charge",
      //   component: () => import("../views/Finance/Charge.vue"),
      // },
      {
        path: "/consumption",
        name: "Consumption",
        component: () => import("../views/Finance/Consumption.vue"),
      },
      {
        path: "/spend",
        name: "Spend",
        component: () => import("../views/Finance/Spend.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/Register.vue"),
  },
  {
    path: "/huashu",
    name: "Mark",
    component: () => import("../views/Speechcraft/Mark.vue"),
  },
];

const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(),
  routes,
  // scrollBehavior: () => {
  //   history.pushState(null, null, document.URL)
  //  }
});
router.beforeEach((to, from, next) => {

  if (to.path !== "/login" && to.path !== "/register") {
    const token = sessionStorage.getItem("token");
    if (!token) {
      next({
        path: "/login",
      });
    } else {
      next();
    }
  } else {
    next(); // 确保一定要调用 next()
  }
});
export default router;
