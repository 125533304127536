<template>
  <a-config-provider :locale="locale">
    <router-view></router-view>
  </a-config-provider>
</template>

<script>
// import * as echarts from 'echarts'
// import { provide } from 'vue'
// import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
import zhCN from "ant-design-vue/es/locale/zh_CN";

export default {
  setup() {
    return { locale: zhCN };
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

html,
body,
#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
/* .ant-table-thead > tr > th {
  padding: 16px 16px 16px 9px !important;
} */
.page {
  width: 100%;
  height: 100%;
  background-color: #fff;
}
/* .ant-modal-content{
  transition: all .3s !important;
} */
.ant-cascader-menu::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.tab1left {
  overflow: auto;
}
.tab1left::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
</style>
